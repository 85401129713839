.service-list li{
    list-style: none;
    padding-top: 8px;
}
.service-list li::before {
    content: '';
    display: inline-block;
    margin-right: 10px;
    height: 13px;
    width: 13px;
    background-image: url("../../assets/diagnostics/white_small_logo.svg");
    background-size: contain;
}
.inner-list{
    padding-left: 45px;
    
}
.inner-list li{
    list-style: disc;
    padding-top: 2px;
}
.inner-list li::before {
    content: none;
    display: inline-block;
    margin-right: 10px;
    height: 13px;
    width: 13px;
    background-image: none;
    background-size: contain;
}