.App {
  text-align: center;
  scroll-behavior: smooth;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.shadow-bottom {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}

body {
  scroll-behavior: smooth;
}


/* typography of Blog contents */
.prose-custom p,
.prose-custom h1,
.prose-custom h2,
.prose-custom ul,
.prose-custom ol,
.prose-custom li,
.prose-custom blockquote {
  margin-top: 0.8rem; /* Reduced margin */
  margin-bottom: 0.8rem; /* Reduced margin */
  line-height: 1.6; /* Adjust line height */
}

.prose-custom h1 {
  font-size: 1.4rem; /* Adjust font size for h1 */
  font-weight: 700; /* Bold font weight */
}

.prose-custom h2 {
  font-size: 1.2rem; /* Adjust font size for h2 */
  font-weight: 500; /* Semi-bold font weight */
}
.prose-custom p {
  font-size: 1.1rem; /* Adjust font size for p */
  font-weight: 500; /* Semi-bold font weight */
}


.prose-custom ul {
  font-size: 1.1rem;
  padding-left: 1rem; /* Adjust list indentation */
  margin-top: 0.5rem; /* Space before the list */
  margin-bottom: 0.5rem; /* Space after the list */
}