.team-curvy-box {
    margin: 100px auto;
    width: 1200px;
    height: 300px;
    background: #222222;
    position: relative;
    overflow-x: clip;
    border-radius: 15px;
}

.team-curvy-box:after {
    content: "";
    position: absolute;
    height: 150px;
    left: -5%;
    right: -5%;
    border-radius: 100%;
    bottom: -45px;
    background: #fff;
}
.outer-team-box::after {
    content: "";
    position: absolute;
    height: 150px;
    left: -5%;
    right: -5%;
    border-radius: 100%;
    bottom: -45px;
    background: #fff;
    
}