.figure {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: auto;
    grid-auto-flow: dense;
    justify-items: stretch; 
    align-items: stretch; 
    gap: 2svmin;
}

.pic {
   width:100%;
   aspect-ratio: 4/3;
   object-fit: cover;
   cursor:pointer;
}

@media (min-width:500px) {
   .figure {
   grid-template-columns: repeat(2, 1fr);
   }  
   .pic:nth-of-type(11) {
   grid-row: span 2 / auto;
  }
}

@media (min-width:1000px) {
   .figure {
   grid-template-columns: repeat(4, 1fr);
   gap: 1svmin;
   }  
  
   .pic:where(:nth-of-type(3), :nth-of-type(12)) {
   grid-row: span 2 / auto;
   grid-column: span 2 / auto;
   }

   .pic:nth-of-type(5) {
   grid-column: span 2 / auto;
   }
  
   .pic:nth-of-type(15) {
   grid-column: span 2 / auto;
   }
}
