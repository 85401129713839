.maindiv {
    background-image: url('../../assets/auth/authlogo.png');
}

@keyframes rotateanime1 {
    from {
        transform: translateX(10px) translateY(-30px) rotate(0deg);
    }

    to {
        transform: translateX(10px) translateY(-30px) rotate(360deg);
    }
}

@keyframes rotateanime2 {
    from {
        transform: translateX(70px) translateY(-20px) rotate(360deg);
    }

    to {
        transform: translateX(70px) translateY(-20px) rotate(0deg);
    }
}

@keyframes rotateanime3 {
    from {
        transform: translateX(-10px) translateY(140px) rotate(0deg);
    }

    to {
        transform: translateX(-10px) translateY(140px) rotate(360deg);
    }
}

.chip1 {
    transform: translateX(10px) translateY(-30px);
    animation: rotateanime1 4s linear infinite;
    background-image: url('../../assets/auth/chip1.png');
    background-size: contain;
    background-repeat: no-repeat;
}

.chip2 {
    transform: translateX(70px) translateY(-20px);
    background-image: url('../../assets/auth/chip2.png'); 
    background-size: contain;
    background-repeat: no-repeat;
    animation: rotateanime2 4s linear infinite;
}

.chip3 {
    transform: translateX(-10px) translateY(140px);
    animation: rotateanime3 4s linear infinite;
    background-image: url('../../assets/auth/chip3.png'); 
    background-size: contain;
    background-repeat: no-repeat;
}

.imgdiv {
    transform: translateX(-10px) translateY(20px);
    background-image: url('../../assets/auth/chip4.png'); 
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}