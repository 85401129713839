.navSecond {
  background: linear-gradient(
    309deg,
    #d9d9d9 -22.56%,
    rgba(217, 217, 217, 0.13) 75.68%
  );
}

.vl {
  border-left: 2px solid white;
  height: 17px;
}

.nav {
  background: rgba(255, 255, 255, 0.14);
  backdrop-filter: blur(14.949999809265137px);
}

/* rgba(217, 217, 217, 1), rgba(217, 217, 217, 0.13) #D9D9D9, #D9D9D921*/
