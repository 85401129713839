.quick_card {
  border-radius: 15px;
  background: #fff;
  box-shadow: 6px 6px 70px 0px rgba(16, 40, 81, 0.23);
}

.moving-logo1 {
  animation: moving-logo-up 90s infinite linear;
  display: inline-block;
  transition: 0.5s;
}

@keyframes moving-logo-up {
  0% {
    transform: translate(50%,
        50%);
  }

  50% {
    transform: translate(0, -100%);
  }
}

.moving-logo2 {
  animation: moving-logo-down 90s infinite linear;
  display: inline-block;
  transition: 0.5s;
}

@keyframes moving-logo-down {
  0% {
    transform: translate(0,
        0);
  }

  100% {
    transform: translate(-400%,
        100%);
  }
}