.exp-list li {
    display: flex;
    align-items: center;
    list-style: none;
    padding-top: 8px;
}

.exp-list li::before {
    content: '';
    display: inline-block;
    margin-right: 15px;
    height: 22px;
    width: 22px;
    background-image: url("../../assets/doctors/Star\ 1.svg");
    background-size: contain;
}

/* .aoe-list {
    list-style-type: disc; 
    list-style-position: inside; 
    padding-left: 0; 
    margin-left: 1em; 
  } */

.aoe-list li {
    /* list-style-type: disc; Use disc markers for list items */
    list-style-position: inside; /* Make sure the marker is inside the list item */
    padding-left: 0; /* Remove default padding */
    /* margin-left: 1em;  */
}

.aoe-list li::before {
    content: '';
    display: inline-block;
    margin-right: 15px;
    height: 22px;
    width: 22px;
    background-image: url("../../assets/doctors/tick.svg");
    background-size: contain;
    
}